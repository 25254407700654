<template>
  <div>
    <bfc-bar></bfc-bar>
    <admin-menu></admin-menu>
    <v-dialog v-model="shippingDialog" max-width="400px"
      ><template>
        <v-container>
          <v-card
            ><v-card-text
              ><v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Case No : {{ shippingInformation.caseNo }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-left"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Shipping Name :
                      {{ shippingInformation.shippingName }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-left"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Tracking No. :
                      {{ shippingInformation.trackingNo }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-left"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Shipping Date :
                      {{ shippingInformation.shippingDate }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-left"></v-list-item-subtitle>
                  </v-list-item-content> </v-list-item></v-list
            ></v-card-text>
            <v-card-footer>
              <v-btn @click="shippingDialog = false">Close</v-btn></v-card-footer
            >
          </v-card>
        </v-container>
      </template>
    </v-dialog>
    <v-card flat class="py-12">
      <v-card-text>
        <v-row align="center" justify="end">
          <v-btn-toggle v-model="toggle_exclusive">
            <!--v-badge bordered :value="10" color="error" overlap
              --><v-btn class="secondary" light @click="showCase('All')">
              All Cases
            </v-btn>
            <!--/-v-badge-->
            <v-btn class="success" light @click="showCase('Submitted')">
              Recieved: New Case Submission
            </v-btn>
            <!--v-btn class="warning" light @click="showCase('RecievedFeedback')">
              Recieved: Doctor's Feedback
            </!--v-btn-->
            <v-btn class="primary" light @click="showCase('Approved')">
              Approved: Treatment Proposal
            </v-btn>
          </v-btn-toggle>

          <!--v-col cols="12" class="text-center"> Model: {{ toggle_exclusive }} </!--v-col-->
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="6">
            <v-text-field
              label="Doctor's name or Patient's name"
              v-model="filterName"
              hint="Doctor's name or Patient's name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="casesTable"
              :keys="caseNo"
              :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, -1] }"
              v-if="casesTable.length > 0"
            >
              <template v-slot:item.updatedAt="{ item }">
                {{ item.updatedAt.substring(0, 10) }}
              </template>
              <template v-slot:item.caseNo="{ item }">
                <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
              </template>
              <template v-slot:item.selectPackage="{ item }">
                {{ item.selectPackage }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ item.status }}
                <span
                  v-if="
                    item.status == 'Approved' && item.shippingInformation === undefined
                  "
                  >( Under fabrication )</span
                >
                <span
                  v-if="
                    item.status == 'Approved' && item.shippingInformation !== undefined
                  "
                  >( Shipped )</span
                >
              </template>
              <template v-slot:item.inspectorCompleted.approvedDate="{item}">
                  {{ item.inspectorCompleted.approvedDate | dateFormat }}
              </template>
              <!--template v-slot:item.inspector="{ item }">

                <v-select
                :return-object="returnObject"
                :items="inspectors"
                :menu-props="{ top: true, offsetY: true }"
                label="Select Inspector"
                v-model="item.inspector"
                @change="changeInspector(item)"
              ></v-select>
                
              </template-->
              <template v-slot:item.actions="{ item }">
                <!--v-btn
                  color="cyan lighten-5"
                  v-if="
                    item.status == 'Approved' && item._package.packageStatus == 'Paid'
                  "
                  @click="showShipping(item.caseNo, item.shippingInformation)"
                >
                  Shipping Information
                </v-btn-->
                <v-btn
                  color="cyan lighten-5"
                  v-if="
                    item.status == 'Approved' && item.shippingInformation !== undefined
                  "
                  @click="showShipping(item.caseNo, item.shippingInformation)"
                >
                  Shipping Information
                </v-btn>

                <!--v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id)"
                  @closeFeedback="closeFeedback"
                  v-if="item.status != 'Draft'"
                >
                  <v-badge v-if=" item.feedback.read === undefined "
                 color="red"
  
                >
                  open Feedback
                  </v-badge>
                </v-btn-->

                <v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item ,'normal')"
                  @closeFeedback="closeFeedback"
                  
                >
                  <v-badge color="orange" v-if="item.status != 'Draft' && isnewFeedback(item)"> open Feedback </v-badge>
                  <v-badge v-else value=""> open Feedback </v-badge>
                  
                </v-btn>

                <!--v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item)"
                  @closeFeedback="closeFeedback"
                  v-else
                >
                  open Feedback
                </v-btn-->

                <v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item,'inspector')"
                  @closeFeedback="closeFeedback"
                  v-if="item.inspector != '' && item.inspector !== undefined "
                >
                  <v-badge color="orange" v-if="isnewInspectorFeedback(item)"> Inspector's Feedback </v-badge>
                  <v-badge v-else value=""> Inspector's Feedback </v-badge>

                </v-btn>

                

                <!--v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item)"
                  @closeFeedback="closeFeedback"
                  v-else
                >
                  Inspector's Feedback
                </v-btn-->




                <template
                  v-if="item.status == 'Waiting for approve' || item.status == 'Approved'"
                >
                  <div>
                    <v-dialog width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="blue lighten-2"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="dialog[item.caseNo] = true"
                        >
                          Download PDF/Media
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title class="headline grey lighten-2">
                            PDF/Media
                          </v-card-title>

                          <v-card-text class="text-left">
                            <a
                              v-if="item.pdfData.files[0]"
                              @click="
                                downloadFile(item.caseNo, item.pdfData.files[0].name)
                              "
                            >
                              Click to download {{ item.pdfData.files[0].name }}</a
                            ><v-divider></v-divider>
                            <a
                              v-if="item.pdfData.files[1]"
                              @click="
                                downloadFile(item.caseNo, item.pdfData.files[1].name)
                              "
                            >
                              Click to download {{ item.pdfData.files[1].name }}</a
                            ><v-divider></v-divider>

                            <a
                              v-if="item.pdfData.files[1]"
                              @click="downloadMultipleFile(item.caseNo, item.pdfData)"
                            >
                              Click to download all files</a
                            >
                            <v-divider></v-divider>
                            <v-btn @click="playVideo(item.caseNo, item.proposal)"
                              >Play Video</v-btn
                            >
                            <v-divider></v-divider>
                            <v-btn @click="openLink(item.video_link)"
                              >EZCheck</v-btn
                            >

                          </v-card-text>

                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-btn color="primary" @click="dialog.value = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </div>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="dialogApprovedInspectorCase"
        @close="close()"
        max-width="600px"
        app
      >
        <v-card>
          <v-card-title class="headline"
            >ปิดเคส {{approvedInspectorCase.caseNo}} สำหรับอินสเปคเตอร์  ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="dialogApprovedInspectorCase = !dialogApprovedInspectorCase"
              >Cancel</v-btn
            >
            <v-btn color="success" @click="dialogApprovedInspectorCaseConfirm">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <select-inspector>
    </select-inspector>
    
    <v-dialog v-model="loadPlayer" max-width="90%" persistent>
      <player-viewer :props="mp4" @closePlayer="closePlayer"></player-viewer>
    </v-dialog>
    <feedback-app
      v-model="feedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="feedbackDialog"
      @closeFeedback="closeFeedback"
      useBy="ADMIN"
      v-if="feedbackCaseId"
    ></feedback-app>

    <inspector-feedback
      v-model="inspectorFeedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="inspectorFeedbackDialog"
      @closeFeedback="closeFeedback"
      useBy="ADMIN"
      v-if="feedbackCaseId"
    ></inspector-feedback>
    <v-dialog
        v-model="dialogChangeInspector"
        @close="cancelSelectInspector"
        max-width="600px"
        app
      >
        <v-card>
          <v-card-title class="headline"
            >Select {{inspector}}  for case no. {{caseNo}} ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="cancelSelectInspector"
              >Cancel</v-btn
            >
            <v-btn color="success" @click="changeInspectorConfirm">Confirm</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>

import feedback from "../../library/feedback/feedback";
import inspectorFeedback from "../../library/inspector-feedback/feedback";
import SystemBar from "../../library/system-bar/system-bar";
import AdminMenu from "../menubar/menubar";
import player from "../../library/video-player/v-player";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { DateTimeFunc } from "../../../libs/constant.js";
export default {
  components: {
    "bfc-bar": SystemBar,
    "admin-menu": AdminMenu,
    "feedback-app": feedback,
    "inspector-feedback": inspectorFeedback,
    "player-viewer": player,

  },
  filters:{
    dateFormat: function (val) {
      if(val !== undefined){
      let ymd = val.substring(0, 10).split("-");
      //return "";
      let month = DateTimeFunc.TH_MONTH(ymd[1]);
      return ymd[2] + " " + month + " " + (parseInt(ymd[0]) + 543)+' '+val.substring(11, 19);
      }else{
      return "";
     }
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      feedbackCaseNo: "",
      feedbackDialog: false,
      inspectorFeedbackDialog: false,
      dialogApprovedInspectorCase: false,
      feedbackCaseId: "",
      shippingDialog: false,
      dialogChangeInspector: false,
      returnObject: true,
      caseNo:"",
      inspector:"",
      caseIndex: "",
      shippingInformation: {
        caseNo: "",
        shippingName: "",
        trackingNo: "",
        shippingDate: "",
      },
      selectedInspector: {},
      //dialog: false,
      loadPlayer: false,
      mp4: [],
      toggle_exclusive: 0,
      filterName: "",
      dates: "",
      cases: [],
      casesTable: [],
      dialog: {},
      approvedInspectorCase:{},
      commission:"",
      headers: [
        {
          text: "Updated",
          value: "updatedAt",
        },
        {
          text: "Case No.",
          value: "caseNo",
        },
        {
          text: "Patient Name",
          value: "patient_name",
        },
        {
          text: "Doctor",
          value: "doctor_name",
        },
        {
          text: "Package",
          value: "selectPackage",
        },
        /*{
          text: "Inspector",
          value: "inspector"
          },
        {
          text: "Inspector Complete Date",
          value: "inspectorCompleted.approvedDate",
        },
        */
        {
          text: "Case Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      newCase: "BFC/newCaseList",
      allCases: "BFC/allCases",
      listSubmitted: "BFC/listSubmitted",
      listApproved: "BFC/listApproved",
      listInspectorUser: "BFC/getInspectorList",
    }),
    
    listNewCase() {
      return this.newCase;
    },
    inspectors(){
        let lists =  this.listInspectorUser.map(item => {
            return {
                text: item.memberId.firstname+ " " + item.memberId.lastname,
                value: item._id
              }
          });
          lists.push({
                text: "All",
                value: ""
              });
          return lists;
      }
  },
  mounted() {
    this.init();
    this.inspectorList();
    this.getConfig({
      code: "Inspector",
      name: "Commission",
      
      //custId: sessionStorage.getItem("custId"),
    }).then((response) => {
      console.log("getConfig");
      console.log(response);
     
    });
  },
  methods: {
    ...mapActions({
      setAdminCasesList: "BFC/setAdminCasesList",
      setInspectorList: "BFC/setListInspector",
    }),

    openLink(url){
        //console.log(caseNo);
        //console.log(url);
        ////console.log(item);
        //console.log("XXXXXXXXXXXXX");
        window.open(url,'_blank');
    },

    async getConfig(cfg) {
      //
      try {
        this.payload = {
          createBy: sessionStorage.getItem("email"),
          updateBy: sessionStorage.getItem("email"),
          custId: sessionStorage.getItem("custId"),

          /*code:"Point",
                    name:"Type",
                    */
          ...cfg,
          //custId: sessionStorage.getItem("custId"),
        };
        await axios
          .post(
            this.url + "/v1/admin/getConfigByName",
            this.payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            //console.log(response)
            //this.pointType = response.data.value;
            //return response.data;
            if(response.data.length > 0){
                this.commission = response.data[0].cfg_value
              }
          });
      } catch (err) {
        this.responseStatus = {
          status: "Error",
          code: 500,
          text: err,
        };
      }
    },
    openInspectorCaseApprove(item){
      
      this.approvedInspectorCase = {
        custId: this.custId,
        inspectorId: item.inspector,
        caseNo: item.caseNo,
        runNo: item.runNo,
        commission: this.commission,
        approvedDate: new Date()
      }
      this.dialogApprovedInspectorCase = true;
      console.log(this.approvedInspectorCase);
    },
   async dialogApprovedInspectorCaseConfirm(){
      console.log(this.approvedInspectorCase);
     
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/cases/completed",
            this.approvedInspectorCase, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              console.log(response.data)
              this.setInspectorList(response.data.data);
              console.log(this.listInspectorUser);
              this.inspector = this.listInspectorUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    isnewFeedback(item){
      //console.log(item.feedback.map(item =>{ return item.read }));
      if( item.feedback.length > 0  ){
          //return (item.feedback.map(item =>{ return item.read }).indexOf("ADMIN") == -1);
          return true;
        }else{
          return true;
        }
    },
    isnewInspectorFeedback(item){
      if( item.inspectorFeedback.length > 0  ){
          //return (item.inspectorFeedback.map(item =>{ return item.read }).indexOf("ADMIN") == -1);
          return true;
        }else{
          return true;
        }
    },
    playVideo(caseNo, videoList) {
      //console.log("playVideo from dashboard");
      this.loadCase = caseNo;
      try {
        this.mp4 = this.playList(videoList);
        this.loadPlayer = true;
      } catch (err) {
        console.log("error " + err);
        alert("Can not play video!");
      }
    },
    playList(videoList) {
      //console.log("playList");
      //console.log(videoList);
      let mp4 = [];
      videoList.forEach((item) => {
        mp4.push({
          url: this.url + "/public/cases/" + this.loadCase + "/mp4/" + item.videoFile,
          name: item.name,
        });
      });
      Promise.all(mp4);
      //console.log(mp4);
      return {
        allowFullScreen: true,
        preload: "auto",
        autoplay: "autoplay",
        sources: mp4,
      };
    },
    closePlayer() {
      console.log("close dialog player");
      this.loadPlayer = false;
    },
    view(caseNo) {
      this.$router.push("/admin/view/" + caseNo);
    },
    showShipping(caseNo, info) {
      //console.log(info);
      //info.push({ caseNo: caseNo });
      if (typeof info !== "undefined") {
        info["caseNo"] = caseNo;
      } else {
        info = { caseNo: caseNo };
      }
      this.shippingDialog = true;
      this.shippingInformation = typeof info === "undefined" ? {} : info;
    },
    closeFeedback(val) {
      //console.log("close feedback");
      this.feedbackDialog = val;
      this.inspectorFeedbackDialog = val;
      this.feedbackCaseNo = "";
      this.feedbackCaseId = "";
      //console.log(this.feedbackDialog);
    },
    openFeedback(caseNo, caseId, item , dialog) {
      //console.log(caseNo + "  " + caseId);
      //console.log(this.feedbackDialog);
      this.feedbackCaseNo = caseNo;
      this.feedbackCaseId = caseId;
      if(dialog != 'inspector'){
        this.feedbackDialog = true;
        //Object.assign(item.feedback,{read:"ADMIN"});
        //console.log(item);
        item.feedback.push({read:"ADMIN"})
      }else{
        this.inspectorFeedbackDialog = true;
        //Object.assign(item.inspectorFeedback,{read:"ADMIN"});
        item.inspectorFeedback.push({read:"ADMIN"})
        }
    },
    downloadFile(caseNo, filename) {
      axios({
        url: this.url + "/public/cases/" + caseNo + "/" + filename,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    downloadMultipleFile(caseNo, PDFFiles) {
      //console.log(PDFFiles);
      for (let i = 0; i < PDFFiles.files.length; i++) {
        let filename = PDFFiles.files[i].name;
        axios({
          url: this.url + "/public/cases/" + caseNo + "/" + filename,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
    },
    async init() {
      console.log("initial data.....");
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/admin/listCases",
            {
              caseStatus: {},
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              this.cases = response.data.data;
              this.casesTable = this.cases
                .filter((item) => {
                  //return item.caseStatus.stateStatus != "Draft";
                  let status = "Draft";
                  if (typeof item.caseStatus !== "undefined") {
                    status = item.caseStatus.stateStatus;
                  }
                  return status != "Draft";
                })
                .map((item) => {
                  //console.log(item.updatedAt.toString());
                  //console.log(item);
                  let _package = {
                    text: "",
                    value: "",
                    price: 0,
                    packageStatus: "None",
                  };
                  let selectPackage = "";
                  if (typeof item.package !== "undefined") {
                    _package = item.package;
                  }

                  if (typeof _package.text !== "undefined") {
                    selectPackage = _package.text;
                  }

                  // if (typeof item.package.text !== "undefined") {
                  //   selectPackage = item.package.text;
                  // }
                  //console.log(_package);
                  let patient_name = item.caseDetail.firstname+' '+item.caseDetail.lastname;

                  let pdfData = {};
                  //let file0 = "";
                  //let file1 = "";
                  if (typeof item.pdfData !== "undefined") {
                    pdfData = item.pdfData;
                  } else {
                    pdfData = {
                      files: [
                        {
                          name: "",
                          mimetype: "",
                        },
                        {
                          name: "",
                          mimetype: "",
                        },
                      ],
                    };
                  }

                  let status = "Draft";
                  if (typeof item.caseStatus !== "undefined") {
                    status = item.caseStatus.stateStatus;
                  }

                  let feedback = [];
                  //console.log(typeof item.feedback);
                  if (item.feedback !== undefined) {
                    feedback = item.feedback;
                  }


                  let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
                  if (item.inspectorFeedback !== undefined) {
                    inspectorFeedback = item.inspectorFeedback;
                  }


                  let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }
                  //console.log(item.caseNo + "    ====>   ");
                  //console.log(item);
                  return {
                    updatedAt: item.updatedAt.toString().substring(0, 10),
                    id: item._id,
                    pdfData: pdfData,
                    caseNo: item.caseNo,
                    video_link: item.video_link || "",
                    inspector: item.inspector || "",
                    current_inspector: item.inspector || "",
                    patient_name:
                      patient_name != " " && typeof patient_name !== "undefined"
                        ? patient_name
                        : item.caseDetail.firstname + " " + item.caseDetail.lastname,
                    doctor_name:
                      item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
                    feedback: feedback,
                    
                    selectPackage: selectPackage,
                    status: status,
                    proposal: item.proposal,
                    shippingInformation: item.shippingInformation,
                    inspectorFeedback: inspectorFeedback,
                    inspectorCompleted: inspectorCompleted,
                    _package: _package,
                    runNo: item.runNo
                  };
                });
              console.log(this.casesTable);
              this.setAdminCasesList(this.cases);
            } else {
              // show error
              console.log("error response");
            }
          });
        //console.log(this.cases);
      } catch (e) {
        console.log(e);
      }
    },

    async inspectorList(){
      let payload = {
        custId: this.custId,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/list",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              console.log(response.data)
              this.setInspectorList(response.data.data);
              console.log(this.listInspectorUser);
              this.inspector = this.listInspectorUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
      },

    prepareData() {
      this.casesTable = this.cases.map((item) => {
        //console.log(item.updatedAt.toString());
        //console.log(item);
        let _package = {
          text: "",
          value: "",
          price: 0,
          packageStatus: "None",
        };
        let selectPackage = "";
        if (typeof item.package !== "undefined") {
          _package = item.package;
        }

        if (typeof _package.text !== "undefined") {
          selectPackage = _package.text;
        }

        // if (typeof item.package.text !== "undefined") {
        //   selectPackage = item.package.text;
        // }
        //console.log(_package);

        let patient_name = item.caseDetail.patient_name;

        let pdfData = {};
        //let file0 = "";
        //let file1 = "";
        if (typeof item.pdfData !== "undefined") {
          pdfData = item.pdfData;
        } else {
          pdfData = {
            files: [
              {
                name: "",
                mimetype: "",
              },
              {
                name: "",
                mimetype: "",
              },
            ],
          };
        }
        let status = "Draft";
        if (typeof item.caseStatus !== "undefined") {
          status = item.caseStatus.stateStatus;
        }

        let feedback = "";
        //console.log(typeof item.feedback);
        if (typeof item.feedback !== undefined) {
          feedback = item.feedback;
        }

        let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
        if (item.inspectorFeedback !== undefined) {
            inspectorFeedback = item.inspectorFeedback;
        }

        let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

        return {
          updatedAt: item.updatedAt.toString().substring(0, 10),
          id: item._id,
          pdfData: pdfData,
          caseNo: item.caseNo,
          video_link: item.video_link || "",
          inspector: item.inspector || "",
          current_inspector: item.inspector || "",
          patient_name:
            patient_name != " " && typeof patient_name !== "undefined"
              ? patient_name
              : item.caseDetail.firstname + " " + item.caseDetail.lastname,
          doctor_name: item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
          feedback: feedback,
          inspectorFeedback: inspectorFeedback,
          selectPackage: selectPackage,
          status: status,
          proposal: item.proposal,
          shippingInformation: item.shippingInformation,
          _package: _package,
          inspectorCompleted: inspectorCompleted,
                    runNo: item.runNo
        };
      });
    },
    uploadPDF(id) {
      this.$router.push("/admin/upload-pdf/" + id);
    },

    updateShippingInformation(id) {
      this.$router.push("/admin/shipping/" + id);
    },

    adminUpload(id) {
      this.$router.push("/admin/admin-upload/" + id);
    },
    selectPackage(id) {
      this.$router.push("/admin/select-package/" + id);
    },

    caseStatusColor(val) {
      let bgColor = "primary";
      //console.log(typeof val.stateStatus);
      //let stateStatus =
      if (typeof val.stateStatus != "undefined") {
        switch (val.stateStatus) {
          case "Submitted":
            bgColor = "success";
            break;
          case "RecievedFeedback":
            bgColor = "warning";
            break;
          case "Approved":
            bgColor = "primary";
            break;
          default:
            //bgColor = "primary";
            break;
        }
      }
      return bgColor;
    },
    async showCase(stateStatus) {
      //console.log(stateStatus);
      switch (stateStatus) {
        case "Submitted":
          //console.log("RecievedNewCase");
          this.casesTable = this.cases
            .map((item) => {
              //console.log(item.updatedAt.toString());
              //console.log(item);
              let _package = {
                text: "",
                value: "",
                price: 0,
                packageStatus: "None",
              };
              let selectPackage = "";
              if (typeof item.package !== "undefined") {
                _package = item.package;
              }

              if (typeof _package.text !== "undefined") {
                selectPackage = _package.text;
              }

              // if (typeof item.package.text !== "undefined") {
              //   selectPackage = item.package.text;
              // }
              //console.log(_package);

              let patient_name = item.caseDetail.patient_name;

              let pdfData = {};
              //let file0 = "";
              //let file1 = "";
              if (typeof item.pdfData !== "undefined") {
                pdfData = item.pdfData;
              } else {
                pdfData = {
                  files: [
                    {
                      name: "",
                      mimetype: "",
                    },
                    {
                      name: "",
                      mimetype: "",
                    },
                  ],
                };
              }
              let status = "Draft";
              if (typeof item.caseStatus !== "undefined") {
                status = item.caseStatus.stateStatus;
              }

              let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
              if (item.inspectorFeedback !== undefined) {
                  inspectorFeedback = item.inspectorFeedback;
              }

              let feedback = "";
              //console.log(typeof item.feedback);
              if (typeof item.feedback !== undefined) {
                feedback = item.feedback;
              }

              let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }



              return {
                updatedAt: item.updatedAt.toString().substring(0, 10),
                id: item._id,
                caseNo: item.caseNo,
                pdfData: pdfData,
                patient_name:
                  patient_name != " " && typeof patient_name !== "undefined"
                    ? patient_name
                    : item.caseDetail.firstname + " " + item.caseDetail.lastname,
                doctor_name:
                  item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
                feedback: feedback,
                inspectorFeedback: inspectorFeedback,
                selectPackage: selectPackage,
                status: status,
                proposal: item.proposal,
                shippingInformation: item.shippingInformation,
                _package: _package,
                video_link: item.video_link || "",
                inspectorCompleted: inspectorCompleted,
                    runNo: item.runNo
                };
            })
            .filter((item) => {
              //console.log(item);
              //console.log(val);
              return item.status == "Submitted";
            });
          //console.log(this.cases);
          break;
        case "Waiting for approve":
          this.casesTable = this.cases
            .map((item) => {
              //console.log(item.updatedAt.toString());
              //console.log(item);
              let _package = {
                text: "",
                value: "",
                price: 0,
                packageStatus: "None",
              };
              let selectPackage = "";
              if (typeof item.package !== "undefined") {
                _package = item.package;
              }

              if (typeof _package.text !== "undefined") {
                selectPackage = _package.text;
              }

              // if (typeof item.package.text !== "undefined") {
              //   selectPackage = item.package.text;
              // }
              //console.log(_package);

              let patient_name = item.caseDetail.patient_name;

              let pdfData = {};
              //let file0 = "";
              //let file1 = "";
              if (typeof item.pdfData !== "undefined") {
                pdfData = item.pdfData;
              } else {
                pdfData = {
                  files: [
                    {
                      name: "",
                      mimetype: "",
                    },
                    {
                      name: "",
                      mimetype: "",
                    },
                  ],
                };
              }
              let status = "Draft";
              if (typeof item.caseStatus !== "undefined") {
                status = item.caseStatus.stateStatus;
              }

              let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
              if (item.inspectorFeedback !== undefined) {
                  inspectorFeedback = item.inspectorFeedback;
              }
              let feedback = "";
        //console.log(typeof item.feedback);
        if (typeof item.feedback !== undefined) {
          feedback = item.feedback;
        }

              let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

              return {
                updatedAt: item.updatedAt.toString().substring(0, 10),
                id: item._id,
                caseNo: item.caseNo,
                pdfData: pdfData,
                video_link: item.video_link || "",
                patient_name:
                  patient_name != " " && typeof patient_name !== "undefined"
                    ? patient_name
                    : item.caseDetail.firstname + " " + item.caseDetail.lastname,
                doctor_name:
                  item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
                feedback: feedback,
                inspectorFeedback: inspectorFeedback,
                selectPackage: selectPackage,
                status: status,
                proposal: item.proposal,
                shippingInformation: item.shippingInformation,
                _package: _package,
                inspectorCompleted: inspectorCompleted,
                    runNo: item.runNo
              };
            })
            .filter((item) => {
              //console.log(item);
              //console.log(val);
              return item.status == "Waiting for approve";
            });
          break;
        case "RecievedFeedback":
          break;
        case "Approved":
          this.casesTable = this.cases
            .map((item) => {
              //console.log(item.updatedAt.toString());
              //console.log(item);
              let _package = {
                text: "",
                value: "",
                price: 0,
                packageStatus: "None",
              };
              let selectPackage = "";
              if (typeof item.package !== "undefined") {
                _package = item.package;
              }

              if (typeof _package.text !== "undefined") {
                selectPackage = _package.text;
              }

              // if (typeof item.package.text !== "undefined") {
              //   selectPackage = item.package.text;
              // }
              //console.log(_package);

              let patient_name = item.caseDetail.patient_name;

              let pdfData = {};
              //let file0 = "";
              //let file1 = "";
              if (typeof item.pdfData !== "undefined") {
                pdfData = item.pdfData;
              } else {
                pdfData = {
                  files: [
                    {
                      name: "",
                      mimetype: "",
                    },
                    {
                      name: "",
                      mimetype: "",
                    },
                  ],
                };
              }
              let status = "Draft";
              if (typeof item.caseStatus !== "undefined") {
                status = item.caseStatus.stateStatus;
              }

              let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
              if (item.inspectorFeedback !== undefined) {
                  inspectorFeedback = item.inspectorFeedback;
              }

              let feedback = "";
        //console.log(typeof item.feedback);
        if (typeof item.feedback !== undefined) {
          feedback = item.feedback;
        }

              let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

              return {
                updatedAt: item.updatedAt.toString().substring(0, 10),
                id: item._id,
                caseNo: item.caseNo,
                pdfData: pdfData,
                video_link: item.video_link || "",
                patient_name:
                  patient_name != " " && typeof patient_name !== "undefined"
                    ? patient_name
                    : item.caseDetail.firstname + " " + item.caseDetail.lastname,
                doctor_name:
                  item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
                feedback: feedback,
                inspectorFeedback: inspectorFeedback,
                selectPackage: selectPackage,
                status: status,
                proposal: item.proposal,
                shippingInformation: item.shippingInformation,
                _package: _package,
                inspectorCompleted: inspectorCompleted,
                    runNo: item.runNo
              };
            })
            .filter((item) => {
              //console.log(item);
              //console.log(val);
              return item.status == "Approved";
            });
          break;
        default:
          //bgColor = "primary";
          this.casesTable = this.casesTable = this.cases
            .map((item) => {
              //console.log(item.updatedAt.toString());
              //console.log(item);
              let _package = {
                text: "",
                value: "",
                price: 0,
                packageStatus: "None",
              };
              let selectPackage = "";
              if (typeof item.package !== "undefined") {
                _package = item.package;
              }

              if (typeof _package.text !== "undefined") {
                selectPackage = _package.text;
              }

              // if (typeof item.package.text !== "undefined") {
              //   selectPackage = item.package.text;
              // }
              //console.log(_package);

              let patient_name = item.caseDetail.patient_name;

              let pdfData = {};
              //let file0 = "";
              //let file1 = "";
              if (typeof item.pdfData !== "undefined") {
                pdfData = item.pdfData;
              } else {
                pdfData = {
                  files: [
                    {
                      name: "",
                      mimetype: "",
                    },
                    {
                      name: "",
                      mimetype: "",
                    },
                  ],
                };
              }

              let status = "Draft";
              if (typeof item.caseStatus !== "undefined") {
                status = item.caseStatus.stateStatus;
              }

              let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
              if (item.inspectorFeedback !== undefined) {
                  inspectorFeedback = item.inspectorFeedback;
              }

              let feedback = "";
        //console.log(typeof item.feedback);
        if (typeof item.feedback !== undefined) {
          feedback = item.feedback;
        }

              let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

              return {
                updatedAt: item.updatedAt.toString().substring(0, 10),
                id: item._id,
                caseNo: item.caseNo,
                pdfData: pdfData,
                video_link: item.video_link || "",
                patient_name:
                  patient_name != " " && typeof patient_name !== "undefined"
                    ? patient_name
                    : item.caseDetail.firstname + " " + item.caseDetail.lastname,
                doctor_name:
                  item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
                feedback: feedback,
                inspectorFeedback: inspectorFeedback,
                selectPackage: selectPackage,
                status: status,
                proposal: item.proposal,
                shippingInformation: item.shippingInformation,
                _package: _package,
                inspectorCompleted: inspectorCompleted,
                    runNo: item.runNo
              };
            })
            .filter((item) => {
              //console.log(item);
              //console.log(val);
              return item.status != "";
            });
          break;
      }
    },
    viewCase(caseNo) {
      this.$router.push("/admin/case/" + caseNo);
    },
    cancelSelectInspector(){
       this.dialogChangeInspector = false;
       this.casesTable[this.caseIndex].inspector = this.casesTable[this.caseIndex].current_inspector;
      },
    changeInspector(item){
        this.caseNo = item.caseNo;
        this.inspector = item.inspector.text;
        this.dialogChangeInspector = true;
        console.log(item);
        this.caseIndex = this.casesTable.indexOf(item);
        //console.log(this.casesTable.indexOf(item));
        this.selectedInspector = item;
        //console.log(this.selectedInspector);
      },
    async changeInspectorConfirm(){
        this.dialogChangeInspector = false;
        let payload = {
            custId: this.custId,
            _id: this.selectedInspector.id,
            inspector: this.selectedInspector.inspector.value
          }

           try {
            axios
              .post(
                this.url + "/api/bfc/v1/inspector/assign",
                payload, // need to change
                {
                  headers: {
                    // Overwrite Axios's automatically set Content-Type
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
               // this.postcodeList = response.data;
               console.log(response)
              });
          } catch (err) {
            this.responseStatus = {
              status: "Error",
              code: 500,
              message: err,
            };
          }
      }
  },
  watch: {
    toggle_exclusive: {
      handler() {
        //console.log(val);
        //console.log(this.toggle_exclusive);
      },
    },
    filterName: {
      handler(val) {
        //console.log("filterName " + val);
        this.casesTable = this.cases
          .map((item) => {
            //console.log(item.updatedAt.toString());
            //console.log(item);
            let _package = {
              text: "",
              value: "",
              price: 0,
              packageStatus: "None",
            };
            let selectPackage = "";
            if (typeof item.package !== "undefined") {
              _package = item.package;
            }

            if (typeof _package.text !== "undefined") {
              selectPackage = _package.text;
            }

            // if (typeof item.package.text !== "undefined") {
            //   selectPackage = item.package.text;
            // }
            //console.log(_package);

            let patient_name = item.caseDetail.patient_name;
            let pdfData = {};
            //let file0 = "";
            //let file1 = "";
            if (typeof item.pdfData !== "undefined") {
              pdfData = item.pdfData;
            } else {
              pdfData = {
                files: [
                  {
                    name: "",
                    mimetype: "",
                  },
                  {
                    name: "",
                    mimetype: "",
                  },
                ],
              };
            }

            let status = "Draft";
            if (typeof item.caseStatus !== "undefined") {
              status = item.caseStatus.stateStatus;
            }

            let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
            if (item.inspectorFeedback !== undefined) {
                  inspectorFeedback = item.inspectorFeedback;
            }

            let feedback = "";
        //console.log(typeof item.feedback);
        if (typeof item.feedback !== undefined) {
          feedback = item.feedback;
        }

            let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

            return {
              updatedAt: item.updatedAt.toString().substring(0, 10),
              id: item._id,
              caseNo: item.caseNo,
              pdfData: pdfData,
              video_link: item.video_link || "",
              patient_name:
                patient_name != " " && typeof patient_name !== "undefined"
                  ? patient_name
                  : item.caseDetail.firstname + " " + item.caseDetail.lastname,
              doctor_name:
                item.doctorId.firstname || "" + " " + item.doctorId.lastname || "",
              feedback: feedback,
              inspectorFeedback: inspectorFeedback,
              selectPackage: selectPackage,
              status: status,
              proposal: item.proposal,
              shippingInformation: item.shippingInformation,
              _package: _package,
inspectorCompleted: inspectorCompleted,
                    runNo: item.runNo
            };
          })
          .filter((item) => {
            //console.log(item);
            //console.log(val);
            let searchDR = item.doctor_name;
            let searchPatient = item.patient_name;
            return (
              searchDR.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              searchPatient.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              item.caseNo.indexOf(val) > -1
            );
          });
      },
    },
    cases: {
      handler(val) {
        //console.log(val);
        if (typeof val === "undefined") {
          this.cases = [];
        }
        this.prepareData();
      },
    },
  },
};
</script>
<style scope>
/* table {
  width: 100%;
}

table td,
table th {
  border: 1px solid;
} */
th {
  text-align: center !important;
}
</style>
